@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Concert+One&family=Neonderthaw&display=swap');

@font-face {
  font-family: 'Retro Rollers';
  src: url(./fonts/Coolville.ttf);
}

.Font {
  font-family: 'Retro Rollers';
}

.Card {
  padding: 20px;
  filter: drop-shadow(0px 0px 5px rgb(180, 34, 194));
}

.actual {
  min-width: 1200px;
}

.App {
  text-align: center;
}

.App-header {
  position: fixed;
  display: flex;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  padding-inline: 30px;
}

.Mint .App-header {
  position: relative;
  padding: 13px 40px 10px 20px;
}

.App-social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.App-social li {
  padding: 5px 10px 0;
}

.App-social li img {
  transform: scale(1.15);
  transition: transform 0.25s ease-in-out 0s;
}

.App-social li img:hover {
  transform: scale(1.4);
}

.App-header .links {
  font-family: 'Retro Rollers';
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: bolder;
  float:left;
  align-self: center;
}

.App-header .links a {
  display: inline-block;
  transition: transform 0.3s ease-in-out 0s;
}

.App-header .links a:hover {
  transform: scale(1.05);
  color:rgb(215, 146, 255);
}

.App-top .Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 0px 0px 0px 0px;
}

.App-top .grid {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.Board {
  width: 95%;
  padding: 15px;
}

.Player {
  width: 95%;
  margin-top: 15px;
  padding: 15px;
}

.Board table td:nth-of-type(1), .tsmall {width: 15%}

.Board table td:nth-of-type(2), .tlarge {width: 33%}

.Board table td:nth-of-type(3) {width: 15%}

/* .Board table td:nth-of-type(4) {width: 33%} */

.Player table td:nth-of-type(1) {width:28%;}
.Player table td:nth-of-type(2) {width:34%;}

.placement {
  color:rgb(237, 177, 48) !important;
  filter: drop-shadow(0px 0px 3px rgb(237, 177, 48)) !important;
}

.placement2 {
  color:rgb(215, 146, 255);
  filter: drop-shadow(0px 0px 3px rgb(237, 48, 205));
}

.Board table {
  width: 100%;
  border-collapse: separate;
  border-right: 3px solid rgb(215, 146, 255);
  border-left: 3px solid rgb(215, 146, 255);
  border-radius: 20px;
  border-spacing: 0px;
}

.Player table {
  width: 100%;
  border-collapse: separate;
  border-right: 3px solid rgb(237, 177, 48);
  border-left: 3px solid rgb(237, 177, 48);
  border-radius: 20px;
  border-spacing: 0px;
}

thead, tbody, tr {
  display: table;
  width: 100%;
  table-layout:auto;
}

.hfont {
  font-size: 50px;
}

.tfont {
  font-size: 30px;
}

.l-font{
  font-size: x-large;
}

.bfont {
  font-size: 30px;
}

.theader {
  margin-left: 30px;
  margin-bottom: 5px;
  line-height: 1.5;
}

.Board th {
  padding: 10px 15px 5px 15px;
  text-align: center;
  /* border-bottom: 3px solid rgb(215, 146, 255); */
}

.Player th {
  padding: 10px 15px 5px 15px;
  /* border-bottom: 2px solid orange; */
}

.Board tfoot tr:last-child th:last-child {
  border-bottom: 3px solid rgb(215, 146, 255);
  box-shadow: 0px 4px 5px rgba(215, 146, 255, 0.76);

}

.Player tfoot tr:last-child th:last-child {
  border-bottom: 3px solid rgb(237, 177, 48);
  box-shadow: 0px 4px 5px rgba(237, 177, 48, 0.678);
}

td {
  padding: 5px;
  text-align: center;
}

.Board tbody tr {
  border-bottom: 2px solid  rgb(215, 146, 255);

  }

.Board tbody tr:last-child {
  border-bottom: 0px;
}

.Player tbody tr {
  border-bottom: 2px solid  rgb(237, 177, 48);
  }

.Player tbody tr:last-child {
  border-bottom: 0px;
}


.Board tbody {
  display: block;
  overflow: auto;
  table-layout: fixed;
  /* max-height:470px; */
  max-height: 35vh;
}

.Player tbody {
  display: block;
  overflow: auto;
  table-layout: fixed;
  /* max-height: 300px; */
  max-height: 20vh;
}

*::-webkit-scrollbar {
  width: 12px;
  scroll-behavior: smooth;
  overflow-y: auto;
  scroll-margin-top: 70px;
}

.Board ::-webkit-scrollbar-track {
  background-color: rgba(215, 146, 255, 0.774);
  border-radius: 20px;
}

.Player ::-webkit-scrollbar-track {
  background-color: rgba(237, 177, 48, 0.884);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #2e2e2e;
  border-radius: 100px;
  border: 1px solid #636363;
  
}

table thead tr:first-child th:first-child {
  border-top-left-radius: 20px;
}

table thead tr:first-child th:last-child {
  border-top-right-radius: 20px;
}

tfoot tr:last-child th {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.btn {
  font-family: 'Retro Rollers';
  letter-spacing: 3px;
  flex: 1 1 auto;
  margin: 10px;
  padding: 18px 10px 10px 10px;
  text-align: center;
  text-transform: uppercase;
  background-size: 200%;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  max-width: 225px;
  font-weight: bolder;
  transition: transform 0.05s ease-in-out 0s;
  transition: 0.5s;
 }

 .btn:hover {
  background-position: right center; /* change the direction of the change here */
  transform: scale(1.05);
  /* color:#3b317d; */
}

.btn-1 {
  background-image: linear-gradient(to right top, #678cf2 0%, rgb(215, 146, 255) 100%);
  font-size: 5vmin;
  text-align:center;
}

.grid .image {
  grid-column: 1/6;
  grid-row: 1/5;
}

.grid .game-button {
  grid-column: 2/5;
  grid-row: 3;
  align-self: center;
}

.Bigmap {
  display: flex;
  flex-direction: column;
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  padding: 40px 10px 0px 10px;
}

.floating {
  overflow: hidden;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.floating2 {
  overflow: hidden;
  transform: translatey(0px);
  animation: float2 6s ease-in-out infinite;
}

.Footer {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  max-height: 500px;
  background-image: url("./media/stars.gif");
  background-position-x: right;
  background-position-y: bottom;
  background-size:cover;
  background-repeat: no-repeat;
  align-items: flex-end;
  justify-content: flex-end;
}


.grid {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  max-width: 100%;
}

.grid .box {
  max-width: 200px;
  justify-self: center;
}

.hover {
  cursor:pointer;
  transition: transform 0.05s ease-in-out 0s;
  transition: 0.5s;
} 

.hover:hover {
  transform: scale(1.2);
}

.help {
  overflow-y: visible;
  padding-top: 20px;
  margin-top:40px;
  padding-bottom: 20px;
}

.hide {
  position: relative;
}

.increment {
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  align-items: center;
  gap: 10px;
  margin-right: 2vw;
}

.shrink {
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sizing {
  width: 160px;
  font-size: 20px;
  padding: 20px 10px 20px 10px;
  filter: drop-shadow(0px 0px 5px rgb(180, 34, 194))
}

.increment .v2 {
  padding-inline: 50px;
  font-family: 'Retro Rollers';
  font-size: 35px;
  text-align: left;
}

.increment .incrementbut1 {
  padding: 10px 5px 10px 5px;
  font-size: 25px;
  background-image: linear-gradient(to left bottom, #678cf2 0%, rgb(215, 146, 255) 100%);
  border-top-left-radius: 99px;
  border-bottom-left-radius: 99px;
  color:rgb(0, 0, 0)
}

.increment .incrementbut1:hover {
  color: white;
}

.increment .incrementbut2 {
  padding: 10px 5px 10px 5px;
  font-size: 25px;
  background-image: linear-gradient(to right top, #678cf2 0%, rgb(215, 146, 255) 100%);
  color:rgb(0, 0, 0);
  border-top-right-radius: 99px;
  border-bottom-right-radius: 99px;
  transition: all 0.1s ease-in-out 0s;
}

.increment .incrementbut2:hover {
  color: white;
  border-color: #9d00ffe0;
}

.MintButton {
  display: inline-block;
  padding: 5px 30px 5px 30px;
  background-image: linear-gradient(to right top, #678cf2 0%, rgb(215, 146, 255) 100%);
  border-width: 2px;
  border-color: #9d00ffe0;
  font-family: 'Retro Rollers';
  color: white;
  font-size: 50px;
  cursor: pointer;
  border-radius: 9px;
  transition: all 0.4s ease-in-out 0s;
  z-index: 1;
}

.MintButton:hover {
  background-image: url('./media/animate.gif');
  background-position:bottom;
  border-width: 2px;
  border-color: #9d00ffe0;
  filter: drop-shadow(0px 0px 10px #9d00ffe0);
  color:rgb(255, 255, 255);
  transform: scale(1.01);
  overflow: hidden;
}

.presaleButton {
  display: inline-block;
  padding: 5px 30px 5px 30px;
  margin-bottom: 10px;
  background-image: linear-gradient(to right top, #678cf2 0%, rgb(215, 146, 255) 100%);
  border-width: 2px;
  border-color: #9d00ffe0;
  font-family: 'Retro Rollers';
  color: white;
  font-size: 20px;
  cursor: pointer;
  border-radius: 9px;
  transition: all 0.4s ease-in-out 0s;
  z-index: 1;
}

.presaleButton:hover {
  background-image: url('./media/animate.gif');
  background-position:bottom;
  border-width: 2px;
  border-color: #9d00ffe0;
  filter: drop-shadow(0px 0px 10px #9d00ffe0);
  color:rgb(255, 255, 255);
  transform: scale(1.01);
  overflow: hidden;
}

.Mint {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  min-height: max-content;
}

.mint-grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1fr;
}

.mint-grid .header {
  grid-row: 2;
  grid-column: 1;
  align-self: flex-end;
  margin-bottom: 150px;
  justify-self: center;
  font-size: 30px;
  z-index: 10;
}

.mint-grid .mint-image {
  grid-row: 1/4;
  grid-column: 1;
  z-index: 0;
  width:100%;
  overflow: hidden;
}

.mint-grid .character {
  grid-row: 1/3;
  grid-column: 1;
  z-index: 10;
  margin-bottom: 18%;
  justify-content: center;
  align-self: center;
  display: inline-flex;
  flex-direction: row;
  height: fit-content;
  gap:20px;
  width: 100%;
}

.NFTs {
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  gap: 80px;
  padding-inline: 40px;
  overflow: "visible";
}

.char {
  max-width: 175px;
  margin: 30px;
  outline-width: 2px;
  outline-color: white;
  outline-offset: 10px;
  outline-style: double;
  border-radius: 20px;
  width: 50vw;
  height: auto;
}

.char-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:10px;
  width: max-content;
  min-width: 100px;
}

.char-buttons .gif {
  padding: 20px 40px 20px 40px;
  background-image: url("./media/GIFButton2.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.25s ease-in-out 0s;
}

.gif:hover {
  transform: scale(1.05);
  background-image: url("./media/GIFHover.png");
}

.char-buttons .emote {
  padding: 20px 40px 20px 40px;
  background-image: url("./media/EmoteStatic.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.25s ease-in-out 0s;
}

.emote:hover {
  transform: scale(1.05);
  background-image: url("./media/EmotePressed.png");
}

.char-buttons .banner {
  padding: 20px 40px 20px 40px;
  background-image: url("./media/BannerStatic.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.25s ease-in-out 0s;
}

.banner:hover {
  transform: scale(1.05);
  background-image: url("./media/BannerPressed.png");
}

.char-buttons .deselect {
  padding: 20px 40px 20px 40px;
  background-image: url("./media/Deselect.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.25s ease-in-out 0s;
}

.deselect:hover {
  transform: scale(1.05);
  background-image: url("./media/DeselectPressed.png");
}

.LeaderBG {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 20px;
}

.lb-logo {
  margin-top: 18px;
  width: 100%;
  max-width: 700px;
}

.mint-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows:max-content 100px max-content;
  padding: 20px 15px 0px 0px;
  max-width: 1250px;
}

.mint-grid-2 .logo {
  grid-row: 1;
  grid-column: 1/6;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-inline: 20px;
}

.mint-grid-2 .type {
  grid-row: 2;
  grid-column: 1/6;
  align-self: center;
  justify-self: center;
}

.mint-grid-2 .mash {
  justify-self: center;
  align-self: center;
  max-width: 300px;
}

.mint-grid-2 .mashup {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row:3;
  grid-column: 1/3;
  justify-self: left;
  align-self: center;
  padding: 40px 40px 40px 40px;
  margin-left: 40px;
}

.mint-grid .nft {
  grid-row: 2;
  grid-column: 1;
  align-self: flex-end;
  z-index: 10;
  height: 200px;
}

.mint1 {
  grid-row: 3;
  grid-column: 1/3;
  align-self: flex-end;
  justify-self: center;
  margin-left: 40px;
  margin-bottom: 10%;
}

.mint2 {
  grid-row: 3;
  grid-column: 3;
  align-self: center;
  justify-self: right;
  /* margin-right: 5%; */
}

.mint3 {
  grid-row: 3;
  grid-column: 3;
  align-self: center;
  justify-self: center;
  margin-right: 15%;
  text-align: center;
}

.gas-container {
  display: flex;
  flex-direction: column;
  justify-content: right;
  justify-self: center;
  grid-row: 3;
  grid-column: 4/6;
  align-self: flex-end;
  position: relative;
  margin-right: 40px;
}

.gas-container .Mint-count {
  position: absolute;
  top: 63%;
  align-self: center;
}

.Mint-count {
  color: #3d3d3d;
  font-family: 'Retro Rollers';
  font-size:8vw;
  text-align: center;
}


.MintStatus {
  font-family: 'Retro Rollers';
  text-transform: uppercase;
  color: white;
  font-size: larger;
  text-shadow: 3px 3px 2px rgba(211, 13, 162, 0.651);
}

.MuiButton-contained {
  font-family: 'Retro Rollers' !important;
  /* filter: drop-shadow(0px 0px 5px rgba(211, 13, 162, 0.651)) !important; */
  padding: 23px 20px 20px 20px !important;
  transition: transform 0.25s ease-in-out 0s !important;
}

.MuiButton-contained:hover {
  transform: scale(1.05);
}

.MuiTypography-root {
  font-family: 'Retro Rollers' !important;
}

.MuiBox-root {
  color: #ffffff !important;
  text-shadow: 2px 2px 2px rgba(211, 13, 162, 0.651) !important;
}

.MuiGrid-root {
  margin: 0px !important;
  padding: 10px 0px 10px 0px !important;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}

.MuiGrid-container {
  /* width: fit-content !important; */
  justify-content: center;
  border-width: 2px;
  border-radius: 20px;
  border-color: rgb(215, 146, 255);
}

.mint-marquee {
  background-image: linear-gradient(to right, #678cf2 0%, rgb(215, 146, 255) 100%);
  height: 45px;
  font-family: 'Retro Rollers';
  font-size: 30px;
  color: white;
  line-height: 1;
}

.mint-marquee2 {
  background-image: linear-gradient(to left, #678cf2 0%, rgb(215, 146, 255) 100%);
  height: 45px;
  font-family: 'Retro Rollers';
  font-size: 30px;
  color: white;
  line-height: 1;
}

.Shadow {
  text-shadow: 3px 3px 2px rgba(211, 13, 161, 0.863);
}

.ShadowNew {
  text-shadow: 3px 3px 2px rgb(237, 177, 48);
}

.showcase {
  animation: slideInFromTop 0.5s ease-in-out;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes float2 {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes slideInFromTop {
  0% {
    transform: translatey(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 840px) {
  .Mint .App-header {
    padding: 20px;
    width: 100%;
  }

  .mint-grid .header {
    font-size: 4.5vw;
  }

  .mint-grid .character {
    margin-bottom: 0;
    justify-content: center;
    align-self: flex-start;
    gap:10px;
    width: 100%;
  }

  .increment {
    display: flex;
    flex-direction: column;
    flex-wrap: none;
    align-items: center;
    gap: 10px;
    margin-right: 0;
  }

  .MintButton {
    padding: 5px 30px 5px 30px;
    background-image: linear-gradient(to right top, #678cf2 0%, rgb(215, 146, 255) 100%);
    border-width: 2px;
    border-color: #9d00ffe0;
    font-family: 'Retro Rollers';
    color: white;
    font-size: 50px;
    cursor: pointer;
    border-radius: 9px;
    transition: all 0.4s ease-in-out 0s;
    z-index: 1;
  }

  .lb-logo {
    margin-top: 68px;
  }

  .hfont {
    font-size: 4vmin;
  }
  
  .tfont {
    font-size: 3.5vmin;
  }

  .bfont {
    font-size: 5vmin;
  }
  

  .presaleButton {
    display: inline-block;
    padding: 5px 30px 5px 30px;
    margin-bottom: 0px;
    background-image: linear-gradient(to right top, #678cf2 0%, rgb(215, 146, 255) 100%);
    border-width: 2px;
    border-color: #9d00ffe0;
    font-family: 'Retro Rollers';
    color: white;
    font-size: 23px;
    cursor: pointer;
    border-radius: 9px;
    transition: all 0.4s ease-in-out 0s;
    z-index: 1;
  }

  .gas-container .Mint-count {
    position: absolute;
    top: 66%;
    align-self: center;
    font-size: 80px;
  }

  .mint-grid-2 {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100%;
    gap: 0px;
    padding: 10px 10px 0px 10px;
  }

  .gas-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    grid-row: 5;
    grid-column: 1;
    width: 80%;
    max-width: 450px;
    margin-right: 0px;
  }

  .mint-grid-2 .logo {
    grid-row: 1;
    grid-column: 1;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-bottom: 20px;
  }
  
  .mint-grid-2 .mash {
    max-width: 225px;
  }
  
  .mint-grid-2 .mashup {
    grid-row:3;
    grid-column: 1;
    justify-self: center;
    align-self: center;
    gap: 20px;
    padding: 20px 25px 20px 25px;
    margin-left: 0px;
  }

  .mint1 {
    grid-row: 4;
    grid-column: 1;
    margin: 0px 0px 20px 0px;
  }

  .mint2 {
    grid-row: 4;
    grid-column: 1;
    margin: 0px 0px 20px 0px;
    justify-self: center;
  }

  .mint3 {
    grid-row: 4;
    grid-column: 1;
    margin: 0px 0px 0px 0px;
    justify-self: center;
    text-align: center;
  }

  .Card {
    margin: 0;
    padding: 20px;
    filter: drop-shadow(0px 0px 5px rgb(180, 34, 194))
  }

  .char {
    width: 30vw;
    max-width: 175px;
    margin: 30px 0px 30px 0px;
    outline-width: 2px;
    outline-color: white;
    outline-offset: 10px;
    outline-style: double;
    border-radius: 20px;
    height: auto;
  }

  .App-header {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
  }

  .Shadow {
    text-shadow: 1px 1px 2px rgba(211, 13, 162, 0.651);
  }

  .ShadowNew {
    text-shadow: 1px 1px 2px rgb(237, 177, 48);
  }
  
  .App-social {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .App-top .grid {
    grid-template-columns: 3fr;
    grid-template-rows: 1fr 1fr;
  }

  .btn {
    padding: 12px 25px 8px 25px;
    border-radius: 5px;
  }

  .btn-1 {
    font-size: 6vmin;
  }
  
  .grid .image {
    grid-column: 1;
    grid-row: 1/3;
  }
  
  .grid .game-button {
    grid-column: 1;
    grid-row: 2/3;
    align-self: flex-start;
    justify-self: center;
  }
  
  .grid {
    max-width: 100vw;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
  }

  .grid .box {
    display: none;
  }

  .grid .box1 {
    grid-row: 2;
    grid-column: 2;
  }

  .Clinks {
    display: inline-flex;
    text-align: center;
  }
}

@media (max-width: 660px) {
  .App-header .links {
    font-family: 'Retro Rollers';
    letter-spacing: 1px;
    font-size: 4.8vmin;
    font-weight: bolder;
    float:left;
    align-self: center;
    margin-bottom: 5px;
  }

  .Clinks {
    display: flex;
    flex-direction: column;
  }

  .lb-logo {
    margin-top: 120px;
  }

  .gas-container .Mint-count {
    position: absolute;
    top: 67%;
    align-self: center;
    font-size: 12vw;
  }

  .mint-grid .character {
    margin-bottom: 0;
    justify-content: center;
    align-self: flex-start;
    margin-top: 30px;
    gap:10px;
    width: 100%;
  }

  .mint-grid-2 .type {
    grid-row: 2;
    grid-column: 1/6;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-size: 6vw;
  }
}

@media (min-width: 1250px) {
  .App-social {
    justify-content: start;
  }

  .Mint-count {
    font-size: 100px;
  }

  .mint1 {
    margin-left: 10px;
  }
  
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Clinks {
  display: inline-flex;
  text-align: center;
}

.glow {
  -webkit-box-shadow: 0px 0px 25px 5px rgba(175, 79, 169, 0.63);
  -moz-box-shadow: 0px 0px 25px 5px rgba(175, 79, 169, 0.63);
  box-shadow: 0px 0px 25px 5px rgba(175, 79, 169, 0.63);
}

.gradient-bg-welcome {
  background-color: #0f0e13;
  background-image: radial-gradient(
      at 0% 0%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 50%
    ),
    radial-gradient(at 50% 0%, hsla(225, 39%, 30%, 1) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsla(339, 49%, 30%, 1) 0, transparent 50%);
}

.gradient-bg-services {
  background-color: #0f0e13;
  background-image: radial-gradient(
      at 0% 0%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 50%
    ),
    radial-gradient(at 50% 100%, hsla(225, 39%, 25%, 1) 0, transparent 50%);
}

.gradient-bg-transactions {
  background-color: #0f0e13;
  background-image: radial-gradient(
      at 0% 100%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 50%
    ),
    radial-gradient(at 50% 0%, hsla(225, 39%, 25%, 1) 0, transparent 50%);
}

.gradient-bg-footer {
  background-color: #0f0e13;
  background-image: radial-gradient(
      at 0% 100%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 53%
    ),
    radial-gradient(at 50% 150%, hsla(339, 49%, 30%, 1) 0, transparent 50%);
}

* html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.links a {
  transition: transform 0.25s ease-in-out 0s;
}
.links a:hover {
  transform: scale(1.15);
}

.myFrame {
  position: absolute;
  width: 173px;
}

.white-glassmorphism {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.black-glassmorphism {
  background: rgba(255, 255, 255, 0.096);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.red-glassmorphism {
  background: rgba(211, 13, 162, 0.15);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.35);
}
